// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agentura-assets-agentura-image-js": () => import("./../../../src/pages/agentura/assets/AgenturaImage.js" /* webpackChunkName: "component---src-pages-agentura-assets-agentura-image-js" */),
  "component---src-pages-agentura-index-js": () => import("./../../../src/pages/agentura/index.js" /* webpackChunkName: "component---src-pages-agentura-index-js" */),
  "component---src-pages-akce-index-js": () => import("./../../../src/pages/akce/index.js" /* webpackChunkName: "component---src-pages-akce-index-js" */),
  "component---src-pages-index-assets-banner-image-js": () => import("./../../../src/pages/index/assets/BannerImage.js" /* webpackChunkName: "component---src-pages-index-assets-banner-image-js" */),
  "component---src-pages-index-index-js": () => import("./../../../src/pages/index/index.js" /* webpackChunkName: "component---src-pages-index-index-js" */),
  "component---src-pages-kontakt-assets-kontakt-image-js": () => import("./../../../src/pages/kontakt/assets/KontaktImage.js" /* webpackChunkName: "component---src-pages-kontakt-assets-kontakt-image-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-nenalezeno-index-js": () => import("./../../../src/pages/nenalezeno/index.js" /* webpackChunkName: "component---src-pages-nenalezeno-index-js" */),
  "component---src-pages-salon-assets-salon-image-js": () => import("./../../../src/pages/salon/assets/SalonImage.js" /* webpackChunkName: "component---src-pages-salon-assets-salon-image-js" */),
  "component---src-pages-salon-index-js": () => import("./../../../src/pages/salon/index.js" /* webpackChunkName: "component---src-pages-salon-index-js" */),
  "component---src-pages-snubni-prsteny-index-js": () => import("./../../../src/pages/snubni-prsteny/index.js" /* webpackChunkName: "component---src-pages-snubni-prsteny-index-js" */),
  "component---src-pages-spolecenske-saty-index-js": () => import("./../../../src/pages/spolecenske-saty/index.js" /* webpackChunkName: "component---src-pages-spolecenske-saty-index-js" */),
  "component---src-pages-svatebni-saty-index-js": () => import("./../../../src/pages/svatebni-saty/index.js" /* webpackChunkName: "component---src-pages-svatebni-saty-index-js" */),
  "component---src-templates-collection-collection-js": () => import("./../../../src/templates/collection/Collection.js" /* webpackChunkName: "component---src-templates-collection-collection-js" */)
}

