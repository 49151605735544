import "./src/styles/reset.scss";
import "./src/styles/fonts.scss";
import "./src/styles/general.scss";
import "./src/styles/noscript.scss";
import "./src/styles/preloader.scss";

import React from "react";
import PageWrapper from "./src/utils/PageWrapper";

export const wrapPageElement = ({element, props}) => {
  return (<PageWrapper {...props}>{element}</PageWrapper>);
};

export const shouldUpdateScroll = ({
  routerProps: {location},
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 500);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      500
    );
  }
  return false;
};